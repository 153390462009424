import React from "react";
import { MenuItem, makeStyles, TextField } from "@material-ui/core";

import I18nText from "../../../../../../elements/I18nText";
import { useContentTypes } from "../../../../../../domain/contentType";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%"
  }
}));

const ContentTypeSelect = React.memo(
  ({ prefix, contentType, setFieldValue, size = "small" }) => {
    const contentTypes = useContentTypes();
    const classes = useStyles();
    const name = `${prefix}.contentType`;

    const options = [...contentTypes];

    options.sort((a, b) => {
      return a.name < b.name;
    });

    if (options.length === 0) {
      return null;
    }

    return (
      <TextField
        classes={classes}
        variant="outlined"
        value={contentType}
        size={size}
        select
        name={name}
        inputProps={{
          id: name
        }}
        onChange={e => setFieldValue(name, e.target.value)}
      >
        <MenuItem value="">&nbsp;</MenuItem>
        {options.map(option => (
          <MenuItem key={option} value={option}>
            <I18nText>{`sensors.contentTypes.${option}`}</I18nText>
          </MenuItem>
        ))}
      </TextField>
    );
  }
);

export default ContentTypeSelect;
