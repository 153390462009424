import React from "react";
import { Grid } from "@material-ui/core";

import HardwareVersion from "./HardwareVersion";

const Device = React.memo(() => {
  return (
    <Grid container>
      <Grid item xs={12} md={9} lg={6}>
        <HardwareVersion />
      </Grid>
    </Grid>
  );
});

export default Device;
