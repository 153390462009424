import React, { useEffect, useState } from "react";

import ResourcesContext from "./ResourcesContext";
import { useApi } from "../api";
import { useAuth } from "../auth";

const ResourcesProvider = ({ children }) => {
  const api = useApi();
  const { user } = useAuth();
  const [smsGateways, setSmsGateways] = useState([]);
  const [contentTypes, setContentTypes] = useState([]);
  const [mobileProviders, setMobileProviders] = useState([]);
  const [reload, doReload] = useState(false);

  useEffect(() => {
    if (user) {
      doReload(true);
    }
  }, [user]);

  useEffect(() => {
    async function fetchSmsGateways() {
      doReload(false);

      const response = await api.get("/v2/sms-gateways");
      setSmsGateways(response.data);
    }

    async function fetchContentTypes() {
      doReload(false);

      const response = await api.get("/v2/content-types");
      setContentTypes(response.data);
    }

    async function fetchMobileProviders() {
      doReload(false);

      const response = await api.get("/v2/mobile-providers");
      setMobileProviders(response.data);
    }

    if (reload) {
      fetchSmsGateways();
      fetchContentTypes();
      fetchMobileProviders();
    }
  }, [api, reload]);

  return (
    <ResourcesContext.Provider
      value={{
        smsGateways,
        contentTypes,
        mobileProviders
      }}
    >
      {children}
    </ResourcesContext.Provider>
  );
};

export default ResourcesProvider;
