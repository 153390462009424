import React from "react";
import { Formik, Form } from "formik";
import { Box, Grid } from "@material-ui/core";

import I18nText from "../../../elements/I18nText";
import {
  CountrySelect,
  UserSelect,
  FullWidthTextField,
  CellNumberField
} from "../../../elements/FormField";
import {
  ButtonsWrapper,
  CancelButton,
  SaveButton
} from "../../../elements/Button";
import Section from "../../../elements/Section";
import SectionHeader from "../../../elements/SectionHeader";
import DebtorSelect from "../../../elements/FormField/DebtorSelect";
import { ROLES, useAuth } from "../../../auth";
import NetDeviceForm from "./NetDeviceForm";
import OnceDeviceForm from "./OnceDeviceForm";
import TypeSelector from "./TypeSelector";
import { DEVICE_SMS_GATEWAY_TYPE, DEVICE_TYPE } from "../../../domain/device";
import LionelDeviceForm from "./LionelDeviceForm";

const MetadataForm = ({
  values,
  smsGateways,
  type,
  onClose,
  validate,
  onSubmit,
  debtorValue,
  onUpdateDebtorValue,
  onUpdateType
}) => {
  const { user, hasRole } = useAuth();

  return (
    <Formik
      initialValues={values}
      validate={validate}
      onSubmit={(values, { setFieldError, setSubmitting }) =>
        onSubmit(values, { setFieldError, setSubmitting })
      }
    >
      {({ submitForm, errors, isSubmitting, values, setFieldValue }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Section>
                <SectionHeader>
                  <I18nText>devices.create.sections.metadata</I18nText>
                </SectionHeader>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <UserSelect
                      name="user"
                      required
                      value={values.user}
                      hasError={errors && errors.user}
                      onUpdate={value => setFieldValue("user", value)}
                      label={
                        <I18nText>devices.settings.metadata.user</I18nText>
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FullWidthTextField
                      name="name"
                      variant="outlined"
                      label={
                        <I18nText>devices.settings.metadata.name</I18nText>
                      }
                      inputProps={{ maxLength: 64, autoComplete: "off" }}
                    />
                  </Grid>
                </Grid>
              </Section>

              <Section>
                <SectionHeader>
                  <I18nText>devices.create.sections.address</I18nText>
                </SectionHeader>
                <Grid container spacing={2}>
                  <Grid item xs={8} md={10}>
                    <FullWidthTextField
                      name="address.street"
                      variant="outlined"
                      label={
                        <I18nText>devices.settings.metadata.street</I18nText>
                      }
                      inputProps={{ maxLength: 128, autoComplete: "off" }}
                    />
                  </Grid>
                  <Grid item xs={4} md={2}>
                    <FullWidthTextField
                      name="address.streetNumber"
                      variant="outlined"
                      label={
                        <I18nText>
                          devices.settings.metadata.streetNumber
                        </I18nText>
                      }
                      inputProps={{ maxLength: 12, autoComplete: "off" }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FullWidthTextField
                      name="address.postalCode"
                      variant="outlined"
                      label={
                        <I18nText>
                          devices.settings.metadata.postalCode
                        </I18nText>
                      }
                      inputProps={{ maxLength: 8, autoComplete: "off" }}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <FullWidthTextField
                      name="address.city"
                      variant="outlined"
                      label={
                        <I18nText>devices.settings.metadata.city</I18nText>
                      }
                      inputProps={{ maxLength: 64, autoComplete: "off" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CountrySelect
                      name="address.countryCode"
                      required
                      label={
                        <I18nText>
                          devices.settings.metadata.countryCode
                        </I18nText>
                      }
                    />
                  </Grid>
                </Grid>
              </Section>

              {hasRole(user, ROLES.MASTER) && (
                <Section>
                  <SectionHeader>
                    <I18nText>devices.create.sections.billing</I18nText>
                  </SectionHeader>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <DebtorSelect
                        name="debtorIdent"
                        value={debtorValue}
                        onUpdate={values => onUpdateDebtorValue(values)}
                        label={
                          <I18nText>
                            devices.settings.billing.debtorIdent
                          </I18nText>
                        }
                      />
                    </Grid>
                  </Grid>
                </Section>
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              <Section>
                <SectionHeader>
                  <I18nText>devices.create.sections.device</I18nText>
                </SectionHeader>
                <TypeSelector selectedType={type} onSelectType={onUpdateType} />

                {type === DEVICE_SMS_GATEWAY_TYPE.LIONEL && (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box marginTop={2}>
                        <CellNumberField
                          name="cellNumber"
                          label={<I18nText>devices.common.cellNumber</I18nText>}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <LionelDeviceForm smsGateways={smsGateways} />
                    </Grid>
                  </Grid>
                )}

                {type === DEVICE_SMS_GATEWAY_TYPE.ONCE && (
                  <Box marginTop={4}>
                    <OnceDeviceForm />
                  </Box>
                )}

                {type === DEVICE_TYPE.NET && (
                  <Box marginTop={4}>
                    <NetDeviceForm
                      value={values.netDevice}
                      hasError={errors && errors.netDevice}
                      onSelect={value => setFieldValue("netDevice", value)}
                    />
                  </Box>
                )}
              </Section>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ButtonsWrapper
                leftButton={
                  <CancelButton onClick={onClose} disabled={isSubmitting} />
                }
                rightButton={
                  <SaveButton onClick={submitForm} disabled={isSubmitting} />
                }
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default MetadataForm;
