import React from "react";
import { CheckboxWithLabel } from "formik-material-ui";
import { FastField } from "formik";

import I18nText from "../../../../../../elements/I18nText";
import BubbleHelpFieldWrapper from "../../../../../../elements/FormField/BubbleHelpFieldWrapper";

const ForceSms = () => (
  <BubbleHelpFieldWrapper id="devices.edit.forceSms">
    <FastField
      component={CheckboxWithLabel}
      name="forceSms"
      color="primary"
      Label={{
        label: <I18nText>devices.settings.configuration.forceSms</I18nText>
      }}
    />
  </BubbleHelpFieldWrapper>
);

export default ForceSms;
