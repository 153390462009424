import React from "react";
import { TableCell, TableRow, makeStyles, useTheme } from "@material-ui/core";
import clsx from "clsx";
import Content from "./Content";
import Indication from "./Indication";
import FormattedDate from "../../elements/FormattedDate";
import CommunicationServer from "./CommunicationServer";
import CommunicationRemote from "./CommunicationRemote";

const useStyles = makeStyles(theme => ({
  cell: {
    "&:hover": {
      cursor: "pointer"
    }
  },
  centerAligned: {
    textAlign: "center"
  },
  communicationServer: {
    paddingRight: 0
  },
  communicationRemote: {
    paddingLeft: 0
  },
  iconOk: {
    color: theme.palette.success.main
  }
}));

const Row = React.memo(({ message, showDeviceId, onSelectMessage }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <TableRow
      hover
      key={message.id}
      onClick={() => onSelectMessage(message.id)}
    >
      <TableCell
        style={{
          borderLeft: `4px solid ${
            theme.custom.colors.messages.statuses[message.status]
          }`
        }}
        className={classes.cell}
      >
        <FormattedDate>{message.createdAt}</FormattedDate>
      </TableCell>
      {showDeviceId && (
        <TableCell className={classes.cell}>{message.deviceId}</TableCell>
      )}
      <TableCell className={classes.cell}>
        <Indication>{message.indication}</Indication>
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.communicationServer)}>
        <CommunicationServer message={message} />
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.communicationRemote)}>
        <CommunicationRemote message={message} />
      </TableCell>
      <TableCell className={classes.cell}>
        <Content messageType={message.type}>{message.content}</Content>
      </TableCell>
    </TableRow>
  );
});

export default Row;
