import React, { useMemo } from "react";
import { Grid, makeStyles, useTheme } from "@material-ui/core";
import JSONPretty from "react-json-pretty";
import XMLViewer from "react-xml-viewer";

import Section from "../../../elements/Section";
import SectionHeader from "../../../elements/SectionHeader";
import I18nText from "../../../elements/I18nText";
import DescriptionList from "../../../elements/DescriptionList";
import { nl2br } from "../../../utils/stringUtils";
import { MESSAGE_TYPE, MESSAGE_DIRECTION } from "../../../domain/message";
import { FORMAT_DATETIME } from "../../../utils/formatting";
import useFormat from "../../../hooks/useFormat";
import { Link } from "react-router-dom";
import FormattedIdent from "../../../elements/FormattedIdent";

const useStyles = makeStyles({
  content: {
    fontSize: 12
  }
});

const Details = ({ message }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { formatDate } = useFormat();

  const xmlTheme = {
    textColor: theme.palette.primary.main,
    tagColor: theme.palette.secondary.main
  };

  const renderContent = useMemo(() => {
    if (!message) {
      return "";
    }

    if (message.type === MESSAGE_TYPE.NET) {
      return <XMLViewer xml={message.content} theme={xmlTheme} />;
    }

    return <pre className={classes.content}>{nl2br(message.content)}</pre>;
  }, [message, classes.content, xmlTheme]);

  if (!message) {
    return "LOADING";
  }

  let dateOption;
  if (message.direction === MESSAGE_DIRECTION.IN) {
    dateOption = {
      label: "messages.common.receivedAt",
      value: message.receivedAt
        ? formatDate(new Date(message.receivedAt), FORMAT_DATETIME)
        : "-"
    };
  } else {
    dateOption = {
      label: "messages.common.sentAt",
      value: message.sentAt
        ? formatDate(new Date(message.sentAt), FORMAT_DATETIME)
        : "-"
    };
  }

  let fromIdent = message.from;
  if (message.type === MESSAGE_TYPE.SMS) {
    fromIdent = (
      <FormattedIdent type={message.fromType}>{message.from}</FormattedIdent>
    );
  }

  let toIdent = message.to;
  if (message.type === MESSAGE_TYPE.SMS) {
    toIdent = (
      <FormattedIdent type={message.toType}>{message.to}</FormattedIdent>
    );
  }

  const listOptions = [
    {
      label: "messages.common.id",
      value: message.id
    },
    {
      label: "messages.common.device",
      value: <Link to={`/devices/${message.deviceId}`}>{message.deviceId}</Link>
    },
    {
      label: "messages.common.type",
      value: <I18nText>{`messages.types.${message.type}`}</I18nText>
    },
    {
      label: "messages.common.indication",
      value: <I18nText>{`messages.indications.${message.indication}`}</I18nText>
    },
    {
      label: "messages.common.direction",
      value: <I18nText>{`messages.directions.${message.direction}`}</I18nText>
    }
  ];

  const listOptions2 = [
    { ...dateOption },
    {
      label: "messages.common.status",
      value: <I18nText>{`messages.statuses.${message.status}`}</I18nText>
    },
    {
      label: "messages.common.fromIdent",
      value: fromIdent
    },
    {
      label: "messages.common.toIdent",
      value: toIdent
    }
  ];

  if (message.username) {
    listOptions.push({
      label: "messages.common.user",
      value: message.username
    });
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Section>
          <SectionHeader>
            <I18nText>messages.sections.metadata</I18nText>
          </SectionHeader>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <DescriptionList options={listOptions} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DescriptionList options={listOptions2} />
            </Grid>
          </Grid>
        </Section>
      </Grid>

      <Grid item xs={12}>
        <Section>
          <SectionHeader>
            <I18nText>messages.sections.content</I18nText>
          </SectionHeader>
          {renderContent}
        </Section>
      </Grid>

      {message.report && (
        <Grid item xs={12}>
          <Section>
            <SectionHeader>
              <I18nText>messages.sections.report</I18nText>
            </SectionHeader>
            <JSONPretty
              id="json-pretty"
              style={{ fontSize: 12 }}
              data={message.report}
            ></JSONPretty>
          </Section>
        </Grid>
      )}
    </Grid>
  );
};

export default Details;
