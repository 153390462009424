import React from "react";

import { MESSAGE_TYPE } from "../../domain/message";

const Content = ({ children, messageType }) => {
  let value = "";

  switch (messageType) {
    case MESSAGE_TYPE.NET:
      value = <em>{"<xml />"}</em>;

      break;

    case MESSAGE_TYPE.MAIL:
      value = children;

      if (value.length > 100) {
        value = <>{value.substr(0, 100)}&hellip;</>;
      }

      break;

    case MESSAGE_TYPE.SMS:
    default:
      value = children;

      if (value.length > 160) {
        value = <>{value.substr(0, 160)}&hellip;</>;
      }
  }

  return value;
};

export default Content;
