import React, { useState } from "react";
import {
  TextField,
  Grid,
  makeStyles,
  Container,
  Paper,
  Box,
  CssBaseline,
  Button
} from "@material-ui/core";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import FlagIcon from "@material-ui/icons/Flag";

import { PrimaryButton } from "../../elements/Button";
import I18nText from "../../elements/I18nText";
import Imprint from "../Imprint";
import PrivacyPolicy from "../PrivacyPolicy";
import LocaleMenu from "../TopBar/LocaleMenu";
import { useLocale } from "../../i18n";
import WhitelabeledLoginLogo from "../Whitelabeling/WhitelabeledLoginLogo";

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(4)
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  loginButton: {
    marginTop: theme.spacing(2)
  },
  demoLabel: {
    marginTop: theme.spacing(2),
    fontSize: 12
  },
  registrationLabel: {
    marginTop: theme.spacing(2),
    fontSize: 12
  }
}));

const Login = ({
  username,
  password,
  loginError,
  setUsername,
  setPassword,
  onSubmit,
  onSubmitDemo,
  showImprint,
  onToggleImprint,
  showPrivacyPolicy,
  onTogglePrivacyPolicy
}) => {
  const classes = useStyles();
  const { locale } = useLocale();
  const [localeMenuAnchor, setLocaleMenuAnchor] = useState(null);

  const openLocaleMenu = event => {
    setLocaleMenuAnchor(event.target);
  };

  const closeLocaleMenu = () => {
    setLocaleMenuAnchor(null);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Paper className={classes.paper}>
        <form className={classes.form} noValidate>
          <Grid container>
            <Grid item xs={12}>
              <WhitelabeledLoginLogo />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                error={!!loginError}
                helperText={
                  !!loginError && (
                    <I18nText>{`login.errors.${loginError}`}</I18nText>
                  )
                }
                margin="normal"
                required
                fullWidth
                label={<I18nText>login.username</I18nText>}
                size="small"
                name="username"
                autoComplete="off"
                inputProps={{
                  autoCapitalize: "off"
                }}
                autoFocus
                value={username}
                onChange={e => setUsername(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                size="small"
                name="password"
                label={<I18nText>login.password</I18nText>}
                type="password"
                autoComplete="current-password"
                id="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
            </Grid>
            {/*
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rememberMe}
                    color="primary"
                    onChange={e => setRememberMe(e.target.checked)}
                    value={rememberMe}
                  />
                }
                label={<I18nText>login.rememberMe</I18nText>}
              />
            </Grid>
              */}
            <Grid item xs={12}>
              <PrimaryButton
                className={classes.loginButton}
                type="submit"
                fullWidth
                onClick={onSubmit}
              >
                <I18nText>login.loginButton</I18nText>
              </PrimaryButton>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.demoLabel}>
                <I18nText>login.demoLabel</I18nText>
              </Box>
              <Button onClick={onSubmitDemo}>
                <KeyboardArrowRightIcon fontSize="small" />
                <I18nText>login.demoButton</I18nText>
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.registrationLabel}>
                <I18nText>login.registrationLabel</I18nText>
              </Box>
              <Button
                href="https://www.tecson.de/oilview-system.html#ov-reg-form"
                target="_blank"
              >
                <KeyboardArrowRightIcon fontSize="small" />
                <I18nText>login.registrationButton</I18nText>
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Box marginTop={2}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button onClick={openLocaleMenu}>
                      <Box fontSize={12} marginRight={1} clone>
                        <FlagIcon />
                      </Box>{" "}
                      <I18nText>{`i18n.localesShort.${locale}`}</I18nText>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button onClick={onToggleImprint}>
                      <I18nText>login.imprint</I18nText>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button onClick={onTogglePrivacyPolicy}>
                      <I18nText>login.privacyPolicy</I18nText>
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <LocaleMenu anchor={localeMenuAnchor} onClose={closeLocaleMenu} />
      {showImprint && <Imprint onClose={onToggleImprint} />}
      {showPrivacyPolicy && <PrivacyPolicy onClose={onTogglePrivacyPolicy} />}
    </Container>
  );
};

export default Login;
